import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../assets/images/logo_elefantinho.png';

function InDevelopment() {
    return (
        <Container>
            <Row>
                <Col className="centralizar">
                    <img src={logo} alt="logo" width="50%"/>
                    <br />
                    Em construção
                </Col>
            </Row>
        </Container>
    );
}

export default InDevelopment;