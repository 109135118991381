import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import InDevelopment from './components/InDevelopment';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Stores from './components/Stores';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <InDevelopment />
    </Router>
  );
}

export default App;
